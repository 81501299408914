// Weird temprary fix for Redirect 301 /our-style /portfolio going to /portfolio/?p=our-style
// let currentUrl = window.location.href.toString();

// if (currentUrl.includes("/portfolio/?p=our-style")) {
//     window.location.href = currentUrl.replace("/?p=our-style", "");
//     console.log('hello')
// }

// if (currentUrl.includes("portfolio?p=our-style")) {
//     window.location.href = currentUrl.replace("?p=our-style", "");
//     console.log('hello')
// }



// ******************************
// animation
// ******************************
( function( $, window, document, undefined )
{
    'use strict';

    var s = document.body || document.documentElement, prefixAnimation = '', prefixTransition = '';

    s = s.style;

    if( s.WebkitAnimation === '' )	prefixAnimation	 = '-webkit-';
    if( s.MozAnimation === '' )		prefixAnimation	 = '-moz-';
    if( s.OAnimation === '' )		prefixAnimation	 = '-o-';

    if( s.WebkitTransition === '' )	prefixTransition = '-webkit-';
    if( s.MozTransition === '' )	prefixTransition = '-moz-';
    if( s.OTransition === '' )		prefixTransition = '-o-';

    $.fn.extend(
        {
            onCSSAnimationEnd: function( callback )
            {
                var $this = $( this ).eq( 0 );
                $this.one( 'webkitAnimationEnd mozAnimationEnd oAnimationEnd oanimationend animationend', callback );
                if( ( prefixAnimation === '' && !( 'animation' in s ) ) || $this.css( prefixAnimation + 'animation-duration' ) === '0s' ) callback();
                return this;
            },
            onCSSTransitionEnd: function( callback )
            {
                var $this = $( this ).eq( 0 );
                $this.one( 'webkitTransitionEnd mozTransitionEnd oTransitionEnd otransitionend transitionend', callback );
                if( ( prefixTransition === '' && !( 'transition' in s ) ) || $this.css( prefixTransition + 'transition-duration' ) === '0s' ) callback();
                return this;
            }
        });
})( jQuery, window, document );

// ************************************************************
/* 
	## inViewport
	Adds & removes an active class to the target element when it enters the viewport
*/
function inViewport(targetClass){
	$(targetClass).each(function(){
		var triggerHeight = $(window).innerHeight() / 1.3;
		var divPos = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();
		var activeClass = targetClass.replace('.','') + "--in-viewport";

		if (divPos <= topOfWindow + triggerHeight ) {
			$(this).addClass(activeClass);
		} else {
			$(this).removeClass(activeClass)
		}
	});
}

function inViewportTargets(){
	inViewport(".js-viewport");
}

$(document).ready(function() {
	inViewportTargets();
});


$(window).scroll(function() {
	inViewportTargets();
});

// ******************************
// accordion
// ******************************
(function($) {
    'use strict';

    $.fn.bgAccordion = function() {
        $(this).each((index, ele) => {
            var $accordion = $(ele),
                $links = $accordion.find('h2 > a');

            $links.on('click', (e) => {
                e.preventDefault();

                var $parent = $(e.currentTarget).parents('li:first');

                $parent.siblings('li').removeClass('open');
                $parent.toggleClass('open');

                if($accordion.parent('div').hasClass('tabbed--wide')) {
                    var $target = $($(e.currentTarget).attr('href'));

                    $target.siblings('.tabs__content').removeClass('open');
                    $target.toggleClass('open');
                }

                return false;
            });
        });

    };

    $('.tabs').bgAccordion();
})(jQuery);

// ******************************
// carousel
// ******************************
(function($) {
    'use strict';

    $.fn.bgCarousel = function() {
        var $carousel = $(this);

        $(document).ready(() => {
            $carousel.slick({
                autoplay: false,
                autoplaySpeed: 8000,
                dots: true,
                dotsClass: 'slick-dots carousel__dots carousel__dots--prop',
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true,
                fade: true,
                prevArrow: '<span class="slick-prev"></span>',
                nextArrow: '<span class="slick-next"></span>'
            });
        });

    };
})(jQuery);

// ******************************
// category filter
// ******************************
(function($) {
    'use strict';

    $.fn.bgCategoryDropDown = function(customOptions) {
        const options = $.extend({}, $.fn.bgCategoryDropDown.defaultOptions, customOptions),
            $target = $(this);

        $target.on('click', (e) => {
            e.preventDefault();

            $target.toggleClass('open');
            $(options.listSelector).toggleClass('open');

            return false;
        });

    };

    $.fn.bgCategoryDropDown.defaultOptions = {
        listSelector: ''
    };
})(jQuery);

// ******************************
// load more
// ******************************
(function($) {
    'use strict';

    $.fn.bgLoadMore = function(customOptions) {
        var $button = $(this),
            options = $.extend({}, $.fn.bgLoadMore.defaultOptions, customOptions),
            count = options.itemsPerPage,
            nextPage = 2;


        $button.on('click', function (e) {
            e.preventDefault();

            if(count < options.totalItems){
                $(options.loadingSelector).show();

                $.get(options.url + 'p' + nextPage, function( elements ) {

                    if (options.useMasonry && $.fn.imagesLoaded)
                    {
                        const $elements = $(elements);
                        $(options.itemsContainer)
                            .append( $elements )
                            .imagesLoaded(() => {
                                $(options.itemsContainer).masonry( 'appended', $elements );
                            });

                    }
                    else
                    {
                        $(options.itemsContainer).append( elements );
                    }

                    $(options.loadingSelector).hide();

                    nextPage++;
                    count = (count) + options.itemsPerPage;

                    if(count >= options.totalItems){
                        $button.hide();
                    }
                });
            }

            return false;
        });
    };

    $.fn.bgLoadMore.defaultOptions = {
        url: '',
        itemsPerPage: 9,
        totalItems: 9,
        itemsContainer: '#container',
        loadingSelector: '#loading',
        useMasonry: false
    };
})(jQuery);

// ******************************
// lookbook
// ******************************
(function($) {
    'use strict';

    $.fn.bgLookbook = function(customOptions) {
        const options = $.extend({}, $.fn.bgLookbook.defaultOptions, customOptions),
            $list = $(this);

        $list.imagesLoaded(() => {
            $list.masonry({
                itemSelector: options.itemSelector
            });
        });

    };

    $.fn.bgLookbook.defaultOptions = {
        itemSelector: ''
    };
})(jQuery);

// ******************************
// menu
// ******************************

(function($) {
    'use strict';

    $.fn.bgMenu = function() {
        var $buttonMenu = $(this),
            $nav = $('.header__nav:first');

        $buttonMenu.on('click', (e) => {
            e.preventDefault();

            if($buttonMenu.hasClass('is-active'))
            {
                $buttonMenu.removeClass('is-active')
                $nav.slideUp();
            }
            else
            {
                $buttonMenu.addClass('is-active');
                $nav.slideDown();
            }

            return false;
        });
    };

    $(document).ready(() => {
        $('#openMainNav').bgMenu();
    });
})(jQuery);

// ******************************
// project
// ******************************
(function($) {
    'use strict';

    $('.photo-credit__link').on('click', (e) => {
        e.preventDefault();

        $(e.currentTarget).parents('.photo-credit:first').toggleClass('open');

        return false;
    });
})(jQuery);

// ******************************
// prop hire
// ******************************
(function($) {
    'use strict';

    const paths = {
                addUrl: '/actions/propHire/basket/add',
                removeUrl: '/actions/propHire/basket/remove',
                clearUrl: '/actions/propHire/basket/clear',
                sendUrl: '/actions/propHire/send'
            },
        $quantitySelect = $('.prop__quantity-select'),
        $quantitySelectErrors = $('#propHireEnquiryFormErrors'),
        errorMessageForm = "Please ensure you have filled out all the fields correctly.",
        errorMessageQuantity = "Please select your quantity";

    const formatSelectLabel = (currItem) => {
        return (currItem.value === '0')? 'Select quantity' : 'Quantity - ' + currItem.value;
    };

    const intializeDropDowns = () => {
        if($quantitySelect.length > 0)
        {
            $quantitySelect
                .selectric({
                    labelBuilder: formatSelectLabel
                })
                .on('change', (e) => {
                    const $form = $(e.currentTarget).parents('form:first');
                    removeQuantityError($form);
                });

        }

    };

    const addQuantityError = ($form, errMsg) => {
        if($('#propHireAddFormErrors').length !== 0) return;

        const $selectric = $form.find('.selectric:first'),
            $error = $('<p />', {'text': errMsg, 'class': 'prop__quantity-error', id: 'propHireAddFormErrors'});

        $form.append($($error));
        $selectric.addClass('error');
    };

    const removeQuantityError = ($form) => {
        const $selectric = $form.find('.selectric:first');

        $('#propHireAddFormErrors').remove();
        $selectric.removeClass('error');
    };

    const addToBasket = (e) => {
        const $form = $(e.currentTarget);

        if($quantitySelect.val() === '0') {
            addQuantityError($form, errorMessageQuantity);
            return false;
        }

        $.post(paths.addUrl, $form.serialize())
            .done((response) => {

                if(typeof response.error !== 'undefined') {
                    addQuantityError($form, response.error);
                    return;
                }

                removeQuantityError($form);
								$('.saved-items-total').text('(' + response.total + ')');
								var buttonText = $('.prop__save').text();
								$('.prop__save').text('Added');
                $('.prop__link--saved').addClass('added').onCSSAnimationEnd(() => {
										$('.prop__link--saved').removeClass('added');
										$('.prop__save').text(buttonText);
                });
            })
            .fail(() => {

            })
            .always(() => {

            });

        return false;
    };

    const emptyBasket = () => {
        $('.saved-items-total').text('');

        $('.saveditems__list').fadeOut('slow', () => {
            $('#propHireBasketEmpty').fadeIn();
            $('.saveditems__list').remove();
        });
    };

    const removeFromBasket = (e) => {
        const $link = $(e.currentTarget),
            data = {
                itemId: $link.data('item-id')
            };

        $.post(paths.removeUrl, data)
            .done((response) => {

                if(response.total > 0)
                {
                    const $row = $link.parents('.saveditem:first');

                    $('.saved-items-total').text('(' + response.total + ')');

                    $row.fadeOut('slow', () => {
                        $row.remove();
                    });
                }
                else
                {
                    emptyBasket();
                }
            })
            .fail(() => {

            })
            .always(() => {

            });

        return false;
    };

    const clearBasket = (e) => {

        e.preventDefault();

        $.post(paths.clearUrl, null)
            .done(() => {
                emptyBasket();
            })
            .fail(() => {

            })
            .always(() => {

            });

        return false;
    };

    const showEnquiryForm = (e) => {
        const $link = $(e.currentTarget),
            target = $link.attr('href'),
            $header = $('.header');

        e.preventDefault();

        if($('.saveditems__list').length < 1) return;

        $(target).show();
        $link.parents('.saveditems__send').fadeOut();

        $('html,body').animate({
            scrollTop: $(target).offset().top - $header.outerHeight()
        }, 1000);
    };

    const $form = $('#propHireEnquiryForm'),
        $formIntro = $('#propHireEnquiryFormIntro'),
        $formErrors = $('#propHireEnquiryFormErrors'),
        $formThanks = $('#propHireEnquiryFormThanks'),
        $submitButton = $('#propHireEnquiryFormSubmit');

    if ($.fn.validate) {
        $('#propHireEnquiryForm').validate({
            highlight: function(input) {
                $(input).addClass('error');

                if(!$formErrors.is(':visible')) {
                    $formErrors.empty().html("<p>" + errorMessageForm + "</p>").show();
                }
            },
            errorPlacement: function(error, element){},
            onfocusout: false,
            submitHandler: function(form) {
                $formErrors.empty().hide();

                const formData = $form.serializeArray();

                $submitButton.text('sending...').prop('disabled', true);

                $.post(paths.sendUrl, formData)
                    .done((response) => {
                        if(response.success === true)
                        {
                            $formIntro.fadeOut();
                            $form.fadeOut(function(){
                                $formThanks.fadeIn();
                            });

                            emptyBasket();
                        }
                        else
                        {
                            $formErrors.empty().html("<p>We're really sorry, but we're unable to send your message at this time. Please try again later.</p>").show();
                        }
                    })
                    .fail(() => {
                        $formErrors.empty().html("<p>We're really sorry, but we're unable to send your message at this time. Please try again later.</p>").show();
                    })
                    .always(() => {
                        $submitButton.text('submit').prop('disabled', false);
                    });

                return false;

            }
        });
    }

    $(document).ready(() => {
        intializeDropDowns();
    });

    $('#js-propFormAdd').on('submit', addToBasket);
    $('#js-propHireClearAll').on('click', clearBasket);
    $('.js-propHireRemove').on('click', removeFromBasket);
    $('#js-propHireShowEnquiryForm').on('click', showEnquiryForm);
})(jQuery);

// ******************************
// waypoints
// ******************************
(function($) {
    'use strict';

    $(document).ready(function() {
        $('.content').waypoint({
            handler: function (direction) {
                if ($(window).width() < 768) return;

                if (direction === 'down') {
                    $('.scroll-up:first').on('click', function (e) {
                        e.preventDefault();

                        $('html, body').animate({
                            scrollTop: 0
                        }, 1000);

                        return false;
                    }).fadeIn();
                }
                else {
                    $('.scroll-up:first').fadeOut();
                }
            },
            offset: -2000
        });
    });
})(jQuery);

// ******************************
// google maps
// ******************************
(function($) {
    'use strict';

    $.fn.scGoogleMap = function(customOptions) {
        var $self = $(this),
            map = null,
            options = $.extend({}, $.fn.scGoogleMap.defaultOptions, customOptions),
            mapOptions = { mapTypeId: google.maps.MapTypeId.ROADMAP, scrollwheel: false, zoomControl: true, draggable: true },
            containerId = $self.attr('id'),
            locationLatLng = options.location.split(','),
            mapCenterLatLng = options.center.split(','),
            location = new google.maps.LatLng(locationLatLng[0], locationLatLng[1]),
            mapCenter = new google.maps.LatLng(mapCenterLatLng[0], mapCenterLatLng[1]);

        var loadMap = function() {
            map = new google.maps.Map(document.getElementById(containerId), mapOptions);
            map.setCenter(mapCenter);
            map.setZoom(options.zoom);

            new google.maps.Marker({
                position: location,
                map: map,
                icon: options.pin.image
            });
        };

        var init = function() {
            $(window).resize(loadMap);

            loadMap();
        };

        init();
    };

    $.fn.scGoogleMap.defaultOptions = {
        zoom: 10,
        pin: {},
        center: '',
        location: ''
    };
})(jQuery);

// ******************************
// quotes
// ******************************
(function($) {
    'use strict';

    $.fn.scQuotes = function() {
        $(this).slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 8000,
            dots: true,
            dotsClass: 'slick-dots carousel__dots',
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });
    };
})(jQuery);

// ******************************
// read more
// ******************************
(function($) {
    'use strict';

    $.fn.scReadMore = function(customOptions) {
        return this.each(function () {
            var $container = $(this),
                $wrapper = $('<div/>', {'class': 'readmore', style: 'display:none;'}),
                $button = $('<a/>', {href: '#!', 'class': 'readmore__button', text: 'Read more'}),
                options = $.extend({}, $.fn.scReadMore.defaultOptions, customOptions);

            if($container.find('> p').length > options.initialParagraphs)
            {
                $container.find('> p').slice(options.initialParagraphs).appendTo($wrapper);
                $wrapper.appendTo($container);
                $button.appendTo($container);

                $button.on('click', (e) => {
                    e.preventDefault();

                    $wrapper.toggle();
                    $button
                        .text(($wrapper.is(':visible')) ? 'Read less' : 'Read more')
                        .toggleClass('open', $wrapper.is(':visible'));

                    return false;
                });
            }
        });
    };

    $.fn.scReadMore.defaultOptions = {
        initialParagraphs: 1
    };
})(jQuery);